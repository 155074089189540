import eventsController from "@/game/keepingitup/controllers/eventsController";
import FloatingTextDefault from "./types/default";
import _BaseFloatingText from "./types/_BaseFloatingText";

export enum FloatingTextTypes {
  DEFAULT = 'floatingTextDefault'
}

export default class FloatingText extends Phaser.GameObjects.Group {
  amount: number = 10;

  constructor(scene: Phaser.Scene) {
    super(scene);

    /** GameObjects */
    for (let index = 0; index < this.amount; index++) {
      const text = new FloatingTextDefault(this.scene);
      this.add(text);
    }

    /** Events */
    eventsController.on('floatingText-spawn', (data: any) => this.spawn(data.x, data.y, FloatingTextTypes.DEFAULT, `+${data.text}`));
  }

  spawn(x: number, y: number, type: FloatingTextTypes, text: string) {
    const pool = this.getMatching('name', type) as _BaseFloatingText[];
    const floatingText = pool.find(child => child.active === false);
    
    if (floatingText) {
      floatingText.revive(x, y, text);
    }
  }
}