import Phaser from "phaser";
import Constants from "../configs/constants";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import eventsController from "../controllers/eventsController";
import scoreController from "@/utils/game/ScoreController";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import { millisToMinutesAndSeconds } from "../configs/helpers";

import Ball from "../components/ball/ball";
import FloatingText from "../components/world/floatingText/floatingText";
import BackgroundTileSprite from "../components/world/background/background";
import ConfettiEmitter, { ConfettiEmitterPosition, ConfettiEmitterSides } from "../components/world/confetti/confettiEmitter";
import Scaling from "../configs/scaling";
import PopupStickerText from "../components/dialog/popupStickerText";
import Setup from "@/helpers/Setup";

export default class Main extends Phaser.Scene {
  lives!: number;
  score!: number;
  startTime!: Date;

  ball!: Ball;
  floatingText!: FloatingText;
  background!: BackgroundTileSprite;
  confetti!: ConfettiEmitter;
  hasNewHighscore!: boolean;
  popupStickerText!: PopupStickerText;

  constructor() {
    super({ key: "game" });
  }

  init() {
    /** API: Start level */
    this.game.events.emit('start');

    /** SAVEFILE */
    sessionSaveFile.create({
      type: ScoreGameTypes.INFINITE,
      stats: ['playtimeStatistic', 'kicks']
    });

    /** SETTINGS */
    this.startTime = new Date();
    this.lives = Constants.PLAYER_LIVES;
    this.score = 0;
    this.hasNewHighscore = false;

    /** SAVEFILE: Default */
    sessionSaveFile.updateValue('lives', this.lives);

    /** SCENES: HUD */
    this.scene.launch('hud');
  }

  create() {
    /** CREATE */
    this.setGameObjects();
    this.setDialogs();
    // this.setControllers();
    // this.setCamera();
    this.setEvents();
    // this.setAudio();

    /** GAME: Start */
    this.start();
  }

  setGameObjects() {
    /** BACKGROUND */
    this.background = new BackgroundTileSprite(this, this.cameras.main.centerX, this.cameras.main.centerY, this.cameras.main.width, this.cameras.main.height);

    /** BALL */
    this.ball = new Ball(this, 0, 0);

    /** EFFECTS: Floating Text */
    this.floatingText = new FloatingText(this);

    /** EFFECTS: Confetti Emitter */
    this.confetti = new ConfettiEmitter(this, 'particle_confetti');
  }

  setDialogs() {
    /** Dialog: Sticker popup */
    this.popupStickerText = new PopupStickerText(this, this.cameras.main.centerX, this.cameras.main.centerY);
  }

  setEvents() {
    /** EVENT: Score add */
    eventsController.on('add-score', (data: any) => {
      this.score += data.amount;
      eventsController.emit('hud-update-score', { amount: this.score });
      sessionSaveFile.updateValue(ScoreDataDefault.TOTALSCORE, this.score);
    })

    /** EVENT: Highscore */
    eventsController.on('highscore-new', () => {
      if (!this.hasNewHighscore) {
        this.hasNewHighscore = true;

        eventsController.emit('sound-play', 'highscore');
        eventsController.emit('popup-stickertext-show', Setup.getValue('settings.game.popup.sticker.highscore')[Setup.language].value);
        eventsController.emit('confetti-emitter-burst', { repeat: 3, position: ConfettiEmitterPosition.BOTTOM, side: ConfettiEmitterSides.BOTH })
      }
    })

    /** EVENT: Life lost */
    eventsController.on('life-lost', (data: any) => {
      this.lives -= data.amount;
      eventsController.emit('hud-update-lives', { amount: this.lives });
      eventsController.emit('popup-stickertext-show', Setup.getValue('settings.game.popup.sticker.loselife')[Setup.language].value);

      this.time.delayedCall(1500, () => {
        if (this.lives === 0) {
          this.gameOver();
        } else {
          eventsController.emit('ball-revive');
        }
      })
    })

    /** EVENT: Audio play */
    eventsController.on('sound-play', (key: string, volume: number = 0.2) => {
      this.sound.play(key, { volume: volume });
    })
  }

  update() {

  }

  start() {
    this.time.delayedCall(1500, () => {
      eventsController.emit('ball-revive');
      eventsController.emit('hud-start-stopwatch');
    })
  }

  gameOver() {
    this.shutdown();
  }

  shutdown() {
    /** Eventcontroller: Remove all listeners */
    eventsController.removeAllListeners();

    /** Scene: Stop current running scenes */
    this.scene.stop();
    this.scene.stop('hud');

    /** PROGRESSION: update playtime */
    sessionSaveFile.updateValue(ScoreDataDefault.PLAYTIME, new Date().getTime() - this.startTime.getTime());
    sessionSaveFile.updateValue('playtimeStatistic', millisToMinutesAndSeconds(new Date().getTime() - this.startTime.getTime()));

    /** END LEVEL */
    const result = scoreController.getResult();
    this.game.events.emit('end', result);
  }
}