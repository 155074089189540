import Phaser from "phaser";
import Scaling from "../configs/scaling";
import CircularProgress from 'phaser3-rex-plugins/plugins/circularprogress';
import Setup from "@/helpers/Setup";
import Background from "./background";

enum LoadStates {
  LOADING = 'loading',
  FINISH_STORY = 'story',
  FINISH_LOAD = 'load'
}

export default class Load extends Phaser.Scene {
  logo!: Phaser.GameObjects.Image;
  loader!: CircularProgress;
  loadProgressRequired: LoadStates[] = [LoadStates.FINISH_LOAD];
  loadProgress: LoadStates[] = [];

  constructor() {
    super({ key: "load" });
  }

  preload() {
    /** Logo */
    this.logo = this.add.image(this.cameras.main.width / 2, (this.cameras.main.height / 2) - (Scaling.getPixelbyDPR(60)), 'campaign_logo');

    /** Progression */
    this.loader = new CircularProgress(this, {
      x: this.cameras.main.centerX, y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
      radius: Scaling.getPixelbyDPR(25),
      trackColor: 0x000000,
      barColor: 0xFFFFFF,
      thickness: 0.1,
      valuechangeCallback: () => { }
    });
    this.add.existing(this.loader);

    /** Loading: Images */
    this.load.image("ball", Scaling.imagePath("ball", "png"));

    this.load.image("icon_score", Scaling.imagePath("icon-score", "png"));
    this.load.image("icon_lives", Scaling.imagePath("icon-lifes", "png"));
    this.load.image("icon_stopwatch", Scaling.imagePath("icon-timer", "png"));
    this.load.image("icon_trophy", Scaling.imagePath("icon-trophy", "png"));
    this.load.atlas("particle_confetti", Scaling.imagePath("spritesheet-confetti", "png"), Scaling.imagePath("spritesheet-confetti-atlas", "json"));
  
    /** Loading: Audio */
    this.load.audio("theme", Setup.getSound("theme.mp3"));
    this.load.audio("fail", Setup.getSound("fail.mp3"));
    this.load.audio("highscore", Setup.getSound("highscore.mp3"));
    this.load.audio("kick", Setup.getSound("kick.mp3"));
    this.load.audio("confetti", Setup.getSound("confetti.mp3"));

    /** Progression: onProgress */
    this.load.on("progress", (value: number) => {
      this.loader.setValue(value);
    });

    /** Progression: onComplete */
    this.load.on("complete", () => {
      this.loader.setAlpha(0);

      this.finish(LoadStates.FINISH_LOAD);
      this.tweens.add({
        targets: this.logo,
        alpha: 0,
        duration: 500,
        ease: 'ease'
      })

      const backgroundScene = this.scene.get("background") as Background;
      backgroundScene.playAudio();
    });    
  }

  finish(state: LoadStates) {
    this.loadProgress.push(state);

    const isCompleted = this.loadProgressRequired.every(progress => this.loadProgress.includes(progress));
    if (isCompleted) {
      this.time.delayedCall(2500, () => this.game.events.emit("loaded"));
    }
  }  
}


