import eventsController from "@/game/keepingitup/controllers/eventsController";

export default class BackgroundTileSprite extends Phaser.GameObjects.TileSprite {
  constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number) {
    super(scene, x, y, width, height, 'bg_game');
    this.scene.add.existing(this);

    /** General */    
    this.setTilePosition((this.scene.textures.get('bg_game').source[0].width/2) - (this.scene.cameras.main.width/2), (this.scene.textures.get('bg_game').source[0].height/2) - (this.scene.cameras.main.height/2));

    /** Event: Background parallax movement based upon ball velocity */
    eventsController.on('background-move', (data: any) => this.move((data.velocityX / 100)));
  }

  move(x: number) {
    this.tilePositionX += x;
  }
}