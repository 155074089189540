import Scaling from "@/game/keepingitup/configs/scaling";

export default class _BaseFloatingText extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene);
    this.scene.add.existing(this);

    /** General */
    this.setActive(false).setVisible(false);
  }

  revive(x: number, y: number, text: string){
    this.setPosition(x, y);
    this.setActive(true).setVisible(true);

    this.animate();
  }

  kill(){
    this.setActive(false).setVisible(false);
  }

  animate(){
    this.scene.tweens.add({
      targets: this,
      alpha: { from: 1, to: 0 },
      y: `-=${Scaling.getPixelbyDPR(100)}`,
      duration: 1000,
      ease: 'Power1',
      onComplete: () => this.kill()
    })    
  }
}