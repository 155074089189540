import Constants from "@/game/keepingitup/configs/constants";
import { getCapeColorAsInterger } from "@/game/keepingitup/configs/helpers";
import Scaling from "@/game/keepingitup/configs/scaling";
import Setup from "@/helpers/Setup";
import { FloatingTextTypes } from "../floatingText";
import _BaseFloatingText from "./_BaseFloatingText";

export default class FloatingTextDefault extends _BaseFloatingText {
  text: Phaser.GameObjects.Text;
  background: Phaser.GameObjects.Arc;

  constructor(scene: Phaser.Scene){
    super(scene);

    /** General */
    this.setName(FloatingTextTypes.DEFAULT);

    /** GameObject: Text */
    this.text = this.scene.add.text(0, 0, '', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      color: Setup.getValue('settings.game.floatingtext.color')
    }).setOrigin(0.5);
    
    /** GameObject: Circle Background */
    this.background = this.scene.add.circle(0, 0, Scaling.getPixelbyDPR(20), getCapeColorAsInterger('settings.game.floatingtext.colorCircle'));
    this.background.setStrokeStyle(Scaling.getPixelbyDPR(2), getCapeColorAsInterger('settings.game.floatingtext.colorCircleOutline'));

    /** Container: Add */
    this.add([this.background, this.text]);   
  }

  revive(x: number, y: number, text: string) {
    super.revive(x, y, text);
    
    /** Set new text and rescale the background */
    this.text.setText(text);
    this.background.setRadius((this.text.width/2) + Scaling.getPixelbyDPR(8));
  }
}