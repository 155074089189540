import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";
import ClickExplosion from "./helpers/clickExplosion";

export default class Ball extends Phaser.Physics.Arcade.Sprite {
  clickExplosion: ClickExplosion;
  worldBounds: Phaser.Geom.Rectangle;

  isShootup: boolean = true;
  angularVelocitySpeed: number = 200;
  movementVelocitySpeed: number = Scaling.getPixelbyDPR(220);

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'ball');
    this.scene.add.existing(this);
    this.scene.physics.world.enable(this);

    /** General */
    this.setActive(false).setVisible(false);

    /** GameObjects */
    this.clickExplosion = new ClickExplosion(this.scene, this.scene.cameras.main.centerX, this.scene.cameras.main.height - 200, Scaling.getPixelbyDPR(30));
    this.worldBounds = new Phaser.Geom.Rectangle(-(this.width / 2), -(this.height / 2), this.scene.cameras.main.width + this.width, this.scene.cameras.main.height + this.height);

    /** Physics */
    this.setAngularDrag(10);
    this.setDrag(100);
    this.setGravity(0, Scaling.getPixelbyDPR(300));
    this.setSize(this.width + Scaling.getPixelbyDPR(12), this.height + Scaling.getPixelbyDPR(12));

    /** Input */
    this.setInteractive().on('pointerdown', (pointer: Phaser.Input.Pointer) => this.kick(pointer))

    /** Events */
    eventsController.on('ball-revive', () => this.revive());
  }

  kick(pointer: Phaser.Input.Pointer) {
    this.move(pointer.x, pointer.y, this.movementVelocitySpeed);
    this.clickExplosion.explode(pointer.x, pointer.y);

    sessionSaveFile.incrementValue('kicks', 1);
    
    eventsController.emit('sound-play', 'kick', 1);
    eventsController.emit('add-score', { amount: 5 });
    eventsController.emit('floatingText-spawn', { 
      x: pointer.x, 
      y: this.y - (this.height/2) - Scaling.getPixelbyDPR(40), 
      text: 5 
    });
  }

  move(x: number, y: number, speed: number) {
    const directionX = this.x + ((x - this.x) * -1);
    const directionY = this.y + ((y - this.y) * -1)

    this.scene.physics.moveTo(this, directionX, directionY, speed);
    this.setAngularVelocity(directionX > 0 ? this.angularVelocitySpeed : -this.angularVelocitySpeed);

    this.scene.tweens.add({
      targets: this,
      scaleX: { from: 1, to: 1.2 },
      scaleY: { from: 1, to: 1.2 },
      duration: 200,
      yoyo: true,
      ease: Phaser.Math.Easing.Bounce
    })
  }

  kill(){
    this.setActive(false).setVisible(false);
    eventsController.emit('sound-play', 'fail');
  }

  revive() {
    this.setPosition(Phaser.Math.Between(0, this.scene.cameras.main.width), this.scene.cameras.main.height + (this.height/2));
    
    this.setShootUp(true);
    this.setVelocity(0);
    this.setAngularVelocity(0);
    this.setActive(true).setVisible(true);

    this.shootUp();
  }

  shootUp(){
    this.scene.physics.moveTo(this, this.scene.cameras.main.centerX, 0, Scaling.getPixelbyDPR(600));
  }

  setShootUp(state: boolean) {
    this.isShootup = state;
  }

  protected preUpdate(time: number, delta: number): void {
    super.preUpdate(time, delta);

    /** Background: Update position based upon velocity */
    eventsController.emit('background-move', { velocityX: this.body.velocity.x});

    /** Ball: Check if ball is out of bounds and is not being shot up*/
    if (this.active && !this.isShootup && !this.worldBounds.contains(this.x, this.y)) {
      this.kill();
      eventsController.emit('life-lost', { amount: 1 });
    }

    /** Ball: Check if ball is shot up and in bounds to remove shootup state */
    if(this.isShootup && this.worldBounds.contains(this.x, this.y)){
      this.setShootUp(false);
    }
  }
}