import Scaling from "@/game/keepingitup/configs/scaling";

export default class ClickExplosion extends Phaser.GameObjects.Arc {
  constructor(scene: Phaser.Scene, x: number, y: number, radius: number) {
    super(scene, x, y, radius);
    this.scene.add.existing(this);

    /** General */
    this.setAlpha(0);
    this.setStrokeStyle(Scaling.getPixelbyDPR(3), 0x000000);
  }

  explode(x: number, y: number) {
    this.setPosition(x, y);

    this.scene.tweens.add({
      targets: this,
      alpha: { from: 1, to: 0 },
      scaleX: { from: 0, to: 1 },
      scaleY: { from: 0, to: 1 },
      duration: 500,
      ease: 'Power1'
    })
  }
}