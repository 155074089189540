import eventsController from "@/game/keepingitup/controllers/eventsController";

export enum ConfettiEmitterPosition {
  TOP = 0,
  MIDDLE = 0.5,
  BOTTOM = 1
}

export enum ConfettiEmitterSides {
  LEFT = 0,
  RIGHT = 1,
  BOTH = 2
}

export default class ConfettiEmitter extends Phaser.GameObjects.Particles.ParticleEmitterManager {
  emitter: Phaser.GameObjects.Particles.ParticleEmitter;

  constructor(scene: Phaser.Scene, key: string) {
    super(scene, key);
    this.scene.add.existing(this);

    /** GameObjects */
    this.emitter = this.createEmitter({
      speed: { min: 200, max: 1200 },
      scale: { start: 1, end: 0 },
      frame: this.frameNames,
      frequency: -1,
      quantity: 100,
      lifespan: 1500,
      gravityY: 800
    });

    /** Events */
    eventsController.on('confetti-emitter-burst', (data: any) => this.explodeRepeated(data.repeat, data.position, data.side));
    eventsController.on('confetti-emitter-explode', (data: any) => this.explodeOnce(data.position, data.side));
  }

  explodeRepeated(repeat: number, position: ConfettiEmitterPosition, side: ConfettiEmitterSides) {
    /** Fire the initial explode */
    this.explodeOnce(position, side);

    /** Repeat the explode for (amount - initial explode - repeat count) times */
    this.scene.time.addEvent({
      repeat: repeat - 2,
      delay: 500,
      callback: () => this.explodeOnce(position, side)
    })
  }

  explodeOnce(position: ConfettiEmitterPosition, side: ConfettiEmitterSides) {
    const x = this.scene.cameras.main.width * side;
    const y = this.scene.cameras.main.height * position;

    /** Angle: Calculate the angle between the emitter position and middle of screen */
    const angle = Phaser.Math.RadToDeg(Phaser.Math.Angle.Between(x, y, this.scene.cameras.main.centerX, this.scene.cameras.main.centerX));

    /** Side - Both: Fire two individual explosions for both sides */
    if (side === ConfettiEmitterSides.BOTH) {
      this.explodeOnce(position, ConfettiEmitterSides.LEFT);
      this.explodeOnce(position, ConfettiEmitterSides.RIGHT);
    } 
    
    /** Side - Left || Right: Fire a single explosion based upon side and position */
    else {
      this.emitter.setAngle({ min: angle - 20, max: angle + 20 }).explode(50, x, y);
    }

    /** Audio: Play confetti sound */
    eventsController.emit('sound-play', 'confetti');
  }
}