import Phaser from "phaser";
import * as Helpers from "../../configs/helpers";
import HUDBox, { IHUDBoxSettings } from "./hudBox";


export default class Stopwatch extends HUDBox {
  updateEvent!: Phaser.Time.TimerEvent;
  elapsedTimeInSeconds: number = 0;

  constructor(scene: Phaser.Scene, settings: IHUDBoxSettings) {
    super(scene, settings);
  }

  start() {
    console.log('update')
    this.updateEvent = this.scene.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => this.updateTime()
    })
  }

  updateTime() {
    /** Time: Progress */
    this.elapsedTimeInSeconds++;

    /** Time: Formatted */
    const formattedTime = Helpers.millisToMinutesAndSeconds(this.elapsedTimeInSeconds * 1000);

    /** Time: update text */
    this.updateText(formattedTime)
  }

  stop(){
    this.updateEvent.remove();
  }
}