import Phaser from "phaser";

export default class Background extends Phaser.Scene {
  background_audio!: Phaser.Sound.BaseSound;

  constructor() {
    super({ key: "background" });
  }

  create() {
    const background = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg_game');
    
    this.game.events.emit("booted");
  }

  setColor(color: number | string) {
    this.cameras.main.setBackgroundColor(color ? color : 0xFFFFFF);
  }

  playAudio() {
    if (!this.background_audio || !this.background_audio.isPlaying) {
      this.background_audio = this.sound.add('theme', { loop: true, volume: 0 });
      this.background_audio.play();
      this.time.delayedCall(400, () => {
        this.tweens.add({
          targets: this.background_audio,
          volume: 0.04,
          duration: 1500
        })
      })
    }
  }

  update() {

  }  
}