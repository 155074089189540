import Setup from "@/helpers/Setup";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";

export default class PopupStickerText extends Phaser.GameObjects.Container {
  textShadow: Phaser.GameObjects.Text;
  text: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** General */
    this.setAlpha(0);

    /** GameObjects */
    this.textShadow = this.scene.add.text(Scaling.getPixelbyDPR(4), Scaling.getPixelbyDPR(4), '', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(40)}px`,
      color: Setup.getValue('settings.game.popup.sticker.colorShadow'),
      stroke: Setup.getValue('settings.game.popup.sticker.colorShadow'),
      align: 'center',
      strokeThickness: Scaling.getPixelbyDPR(4),
      wordWrap: {
        width: Scaling.getPixelbyDPR(200)
      }
    }).setOrigin(0.5).setAlpha(0.5);

    this.text = this.scene.add.text(0, 0, '', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(40)}px`,
      color: Setup.getValue('settings.game.popup.sticker.color'),
      stroke: Setup.getValue('settings.game.popup.sticker.colorOutline'),
      align: 'center',
      strokeThickness: Scaling.getPixelbyDPR(4),
      wordWrap: {
        width: Scaling.getPixelbyDPR(200)
      }
    }).setOrigin(0.5);

    /** Add gameobjects to container */
    this.add([this.textShadow, this.text]);

    /** Events */
    eventsController.on('popup-stickertext-show', (text: string) => this.show(text));
  }

  show(text: string){
    this.textShadow.setText(text);
    this.text.setText(text);
    this.animate();
  }

  animate() {
    this.scene.tweens.add({
      targets: this,
      scaleX: { from: 0, to: 1 },
      scaleY: { from: 0, to: 1 },
      angle: { from: 180, to: 0 },
      alpha: { from: 0, to: 1 },
      duration: 400,
      hold: 400,
      yoyo: true,
      ease: Phaser.Math.Easing.Back.Out
    })
  }
}