import Setup from "@/helpers/Setup";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import Phaser from "phaser";
import HUDBox from "../components/hud/hudBox";
import Stopwatch from "../components/hud/stopwatch";
import Constants from "../configs/constants";
import { getCapeColorAsInterger } from "../configs/helpers";
import Scaling from "../configs/scaling";
import eventsController from "../controllers/eventsController";

export default class HUD extends Phaser.Scene {
  isDebugmode!: boolean;
  fpsTracker!: Phaser.GameObjects.Text;

  constructor() {
    super({ key: "hud" });
  }

  init(data: any) {
    this.isDebugmode = data.debug || false;
  }

  create() {
    /** Debug: Set FPS tracker (optional) */
    this.fpsTracker = this.add.text(this.cameras.main.width - Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(16), `${0} - FPS`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: '#fff',
      align: 'right'
    }).setOrigin(1, 0);
    this.fpsTracker.setActive(this.isDebugmode).setVisible(this.isDebugmode);

    /** Stopwatch: Playtime tracker */
    const stopwatch = new Stopwatch(this, {
      x: Scaling.getPixelbyDPR(16),
      y: Scaling.getPixelbyDPR(64),
      iconTexture: 'icon_stopwatch',
      color: {
        textBox: getCapeColorAsInterger('settings.game.hud.boxTextColor'),
        iconBox: getCapeColorAsInterger('settings.game.hud.boxIconColor'),
        text: Setup.getValue('settings.game.hud.textColor')
      },
      value: '00:00',
      minWidth: Scaling.getPixelbyDPR(65),
    });

    /** Lives: Tracker */
    const lives = new HUDBox(this, {
      x: Scaling.getPixelbyDPR(16),
      y: stopwatch.getBounds().bottom + Scaling.getPixelbyDPR(18),
      iconTexture: 'icon_lives',
      color: {
        textBox: getCapeColorAsInterger('settings.game.hud.boxTextColor'),
        iconBox: getCapeColorAsInterger('settings.game.hud.boxIconColor'),
        text: Setup.getValue('settings.game.hud.textColor')
      },
      value: `${Constants.PLAYER_LIVES}`,
      minWidth: Scaling.getPixelbyDPR(65)
    });

    /** Score: Tracker */
    const score = new HUDBox(this, {
      x: this.cameras.main.width - Scaling.getPixelbyDPR(16),
      y: Scaling.getPixelbyDPR(64),
      iconTexture: 'icon_score',
      color: {
        textBox: getCapeColorAsInterger('settings.game.hud.boxTextColor'),
        iconBox: getCapeColorAsInterger('settings.game.hud.boxIconColor'),
        text: Setup.getValue('settings.game.hud.textColor')
      },
      value: '0',
      minWidth: Scaling.getPixelbyDPR(65),
      origin: {
        x: 1
      }
    });

    /** Highscore: Tracker */
    const highscore = new HUDBox(this, {
      x: this.cameras.main.width - Scaling.getPixelbyDPR(16),
      y: score.getBounds().bottom + Scaling.getPixelbyDPR(18),
      iconTexture: 'icon_trophy',
      color: {
        textBox: getCapeColorAsInterger('settings.game.hud.boxTextColor'),
        iconBox: getCapeColorAsInterger('settings.game.hud.boxIconColor'),
        text: Setup.getValue('settings.game.hud.textColor')
      },
      value: `${globalSaveFile.getHighscore()}`,
      minWidth: Scaling.getPixelbyDPR(65),
      origin: {
        x: 1
      }
    });

    /** Events */
    eventsController.on('hud-start-stopwatch', () => stopwatch.start());
    eventsController.on('hud-update-score', (data: any) => {
      score.updateText(data.amount);
      score.animateText();

      /** Highscore: Update if score exceeds highscore */
      if (data.amount > globalSaveFile.getHighscore()) {
        highscore.updateText(data.amount);
        highscore.animateText();
        eventsController.emit('highscore-new');
      }
    });
    eventsController.on('hud-update-lives', (data: any) => {
      lives.updateText(data.amount);
      lives.animateText();
    });
  }

  shutdown() {
    this.scene.stop();
  }

  update() {
    if (this.isDebugmode) {
      this.fpsTracker.setText(`${Math.floor(this.game.loop.actualFps)} - FPS`);
    }
  }
}
